<template>
    <el-dialog :model-value="show" title="登录数达到上限，请查看登录列表" width="50%" @close="handleClose" @open="handleOpen">
        <div class="table_box" v-loading="loading">
            <el-table :data="tableData" :header-cell-style="{ background: '#f2f2f2' }">
                <el-table-column prop="sectionName" label="部门" />
                <el-table-column prop="userName" label="姓名" />
            </el-table>
            <div class="page_box">
                <el-pagination background layout="prev, pager, next" :total="total" v-model:current-page="query.pageNum"
                    :page-size="query.pageSize" />
            </div>
        </div>
    </el-dialog>
</template>

<script setup>
import { ref, reactive, watch } from 'vue';
import { onlineList } from '@/request/api';

const props = defineProps({
    show: { type: Boolean, default: false },
    formData: { type: Object, default: {} }
})

const emits = defineEmits(['close'])

const loading = ref(false)
const query = reactive({
    pageNum: 1,
    pageSize: 10
})
const total = ref(0)
const tableData = ref([])

async function getList() {
    loading.value = true;
    const params = { name: props.formData.name, password: props.formData.password, ...query }
    const { data } = await onlineList(params);
    loading.value = false;
    total.value = data?.total || 0;
    tableData.value = data?.list || [];
}

watch(() => query.pageNum, getList)

// 打开
function handleOpen() {
    getList();
}

// 关闭
function handleClose() {
    emits('close')
}
</script>

<style lang="scss" scoped>
.page_box {
    display: flex;
    justify-content: center;
    padding-top: 30px;
}
</style>