<template>
    <div class="login_container">
        <div class="page_name">
            <p>欢迎登录</p>
        </div>
        <div class="form_box">
            <el-form :model="formData" autocomplete="off">
                <ul>
                    <el-form-item>
                        <li>
                            <input type="text" v-model.trim="formData.name" id="username" />
                            <label class="label_name" :class="formData.name ? 'small' : ''" for="username">账号</label>
                        </li>
                    </el-form-item>
                    <el-form-item>
                        <li>
                            <input type="password" v-model.trim="formData.password" id="password" />
                            <label class="label_name" :class="formData.password ? 'small' : ''" for="password">密码</label>
                        </li>
                    </el-form-item>
                    <el-form-item>
                        <li>
                            <input type="text" v-model.trim="formData.code" id="code" :maxlength="4" />
                            <label class="label_name" :class="formData.code ? 'small' : ''" for="code">验证码</label>
                            <img class="code_img" @click="getCode" :src="codeImg" alt="" />
                        </li>
                    </el-form-item>
                </ul>
                <div class="btn_row">
                    <button class="btn" @click.prevent="onLogin">提交</button>
                </div>
            </el-form>
            <div class="link_row">
                <router-link to="/login/backpassword">忘记密码？</router-link>
            </div>
        </div>
    </div>
    <!-- 在线人数弹框 -->
    <OnLineDialog :show="onLineShow" :formData="formData" @close="onLineClose" />
</template>

<script setup>
import { reactive, ref } from "vue";
import { apiCodeGetCode, authLogin } from "@/request/api";
import { ElMessage } from "element-plus";
import withLoading from "@/utils/loading";
import { setStorage } from "@/utils";
import { useRouter } from "vue-router";
import OnLineDialog from "./on-line/OnLineDialog.vue";
import useOnLine from "./on-line/useOnLine";

const $router = useRouter();
const codeImg = ref("");
const formData = reactive({
    name: "",
    password: "",
    code: "",
    imageCode: "",
});

// 获取验证码
async function getCode() {
    const { data } = await apiCodeGetCode();
    codeImg.value = data.imgBase64;
    formData.imageCode = data.code;
}

// 登录
function onLogin() {
    const { name, password, code } = formData;
    if (!name) {
        ElMessage.error("请输入账号");
        return;
    }
    if (!password) {
        ElMessage.error("请输入密码");
        return;
    }
    if (!code) {
        ElMessage.error("请输入验证码");
        return;
    }
    login();
}

// 登录请求
async function login() {
    const { code, data } = await withLoading(authLogin, { text: "登录中..." })(formData);
    if (code === "0") {
        setStorage("name", data.name);
        setStorage("token", data.token);
        setStorage("logo", data.logo);
        setStorage("information", data.information || "Y");
        if (data?.code) {
            setStorage("passport_code", data?.code);
            setStorage("passport_msg", data?.msg);
        }
        $router.replace("/");
    }
    if (code === "604") {
        // 超出最大在线人数
        onLineOpen();
    } else {
        getCode();
    }
}

// 当前在线
const { show: onLineShow, open: onLineOpen, close: onLineClose } = useOnLine();

getCode(); // 获取验证码
</script>

<style lang="scss" scoped>
.login_container {
    height: 100%;
    padding-top: 80px;

    .page_name {
        text-align: center;

        p {
            color: #292d38;
            font-size: 36px;
        }
    }

    .form_box {
        padding: 0 66px;
        padding-top: 40px;

        ul {
            li {
                position: relative;
                border-bottom: 1px solid #d8d8d8;
                height: 66px;
                padding-top: 44px;

                .label_name {
                    position: absolute;
                    left: 5px;
                    top: 30px;
                    font-size: 16px;
                    color: #070a16;
                    transition: all 0.3s linear;
                    cursor: text;

                    &.small {
                        top: 0;
                        left: 0;
                        color: #999;
                        font-size: 14px;
                    }
                }

                input {
                    width: 100%;
                    height: 50px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    background: none;
                    border: none;
                    font-size: 16px;
                    padding: 0 5px;

                    &:focus {
                        & + .label_name {
                            top: 0;
                            left: 0;
                            color: #999;
                            font-size: 14px;
                        }
                    }

                    // 去掉填充后的背景色
                    &:-webkit-autofill,
                    &:-webkit-autofill:hover,
                    &:-webkit-autofill:focus,
                    &:-webkit-autofill:active {
                        -webkit-transition-delay: 111111s;
                        -webkit-transition: color 11111s ease-out, background-color 111111s ease-out;
                    }
                }

                .code_img {
                    width: 90px;
                    height: 32px;
                    border: 1px solid #d9d9d9;
                    position: absolute;
                    bottom: 10px;
                    right: 0;
                    cursor: pointer;
                }
            }
        }

        .btn_row {
            margin-top: 30px;

            .btn {
                width: 100%;
                height: 48px;
                line-height: 48px;
                border-radius: 8px;
                background: linear-gradient(-63.43deg, rgba(0, 149, 212, 1) 0%, rgba(0, 92, 169, 1) 100%);
                color: #fff;
                font-size: 18px;
                letter-spacing: 5px;
            }
        }
    }

    .link_row {
        padding-top: 24px;

        a {
            color: #afb4bb;
            font-size: 12px;
        }
    }
}
</style>
